<template>
  <div class="about">
    <div class="resume">
      <h1>Bienvenue dans l'univers de Kub'z!</h1>
      <p>
        Ici, tout n'est que kubes et empilements de kubes. Vous incarnez un jeune héro prometteur
        qui
        veut se faire une place dans ce monde féroce et évoluant à une vitesse folle.<br><br>
        Menez votre Kube sur une grille de 9x9 sur laquelle vont se balader des Kubes ennemis qui
        ont chacun un mode de déplacement bien particulier. <br><br>

        Ne vous faites pas toucher et atteignez la cible
        pour passer au niveau suivant. Survivez le plus longtemps possible, atteignez le score le
        plus
        élevé et inscrivez votre score parmi les meilleurs joueurs du monde.
      </p>
      <div class="gameplay">
        <img src="../../public/images/Cube_qui_saute.gif" alt="">
        <img src="../../public/images/Cube_qui_avance.gif" alt="">
        <img src="../../public/images/Cube_qui_meurt.gif" alt="">
      </div>
    </div>

    <div class="team">
      <h1>L'équipe de développement :</h1>
      <div class="devs">
        <div class="dev" v-for="(dev, k) in team " :key="k">
          <img :src="require(`../../public/images/equipe/${dev.photo}.jpg`)" alt="">
          <span class="name">{{ dev.name }}</span>
          <span class="role">{{ dev.role }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'About',
  data() {
    return {
      team: [
        {
          name: 'Luca',
          role: 'Lead Project',
          photo: 'luca',

        },
        {
          name: 'Chakib',
          role: 'Lead dev',
          photo: 'chakib',

        },
        {
          name: 'Thybault',
          role: 'Conception & Web dev',
          photo: 'thybault',

        },
        {
          name: 'Antoine',
          role: 'QA tester',
          photo: 'antoine',

        },
        {
          name: 'Simon',
          role: 'Lead animator',
          photo: 'simon',

        },
      ],
    };
  },
};
</script>

<style scoped>
.about {
  display: flex;
  flex-wrap: wrap;
  padding: 50px;
  margin-left: 25px;
}

.resume {
  margin-bottom: 70px;
}

h1 {
  color: var(--color-accent);
  margin-bottom: 25px;
}

p {
  line-height: 25px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  width: 80%;
}
.team{
  width: 100%;
}
.devs {
  display: flex;
  justify-content: space-around;
  margin: 50px auto 0 auto;
;
}

.dev {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dev img {

  margin-bottom: 10px;
  width: 175px;
}
.dev .name{
  color: var(--color-accent);
}
.dev .role {
  font-family: "Open Sans", sans-serif;
  margin-top: 5px;
}
.gameplay{
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.gameplay img{
  width: 200px;
  height: 200px;
}
</style>
